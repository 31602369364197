<template>
<div class="RichText">
    <editor api-key="e7a903789b1435ac4a63004f5a288e468f06fd907e6ebc36db321078b25037cc" :init="options" class="tinymce" v-model="input" />
</div>
</template>

<script>
 var Editor = require('@tinymce/tinymce-vue').default;
 export default {
     components: {
         'editor': Editor
     },
     props: ['value'],
     data() {
         return {
             input: '',
             options: {
                 height: 600,
                 menubar: true,
                 paste_data_images: true,
                 plugins: ['advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks code fullscreen', 'fullscreen', 'insertdatetime media table paste code help wordcount'],
                 toolbar: 'insertfile undo redo | styleselect | fontselect | fontsizeselect  | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | \
                forecolor backcolor emoticons',
                 font_formats: 'Stem-Medium=Stem-Medium;  Stem-Regular=Stem-Regular; Stem-Bold=Stem-Bold;',
                 content_css: 'https://avior.dbms.in/styles/main.css', 
                 font_css: 'https://avior.dbms.in/styles/main.css',
                 content_style: 'body { font-family: Stem-Regular;}',
                 images_upload_handler: function (blobInfo, success) {
                     success("data:" + blobInfo.blob().type + ";base64," + blobInfo.base64());
                 }
             },
         }
     },
     watch: {
        value: {
             handler(after) {
                 this.input = after;
             },
             immediate: true,
         },
         input() {
             this.$emit('input', this.input);
         }
     },
 }
</script>
